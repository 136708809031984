import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Link from "./link";

import { StaticImage } from "gatsby-plugin-image";

const CtaBanner = ({ headline, text }) => {
	return (
		<section className="mt-7">
			<Row className="align-items-lg-center py-0 bg-primary ">
				<Col
					xs={12}
					lg={7}
					className="bg-primary text-white px-lg-9 px-md-6 px-5 py-5 py-md-7 py-lg-0 "
				>
					<h3 className="pb-4">{headline}</h3>
					<p className=" pb-md-4 pb-xl-4 pb-lg-2 pb-2">{text}</p>
					<Link to="/contact-us" className=" cta-link cta-link-white">
						CONTACT US
					</Link>
				</Col>

				<Col
					xs={{ span: 12, order: "first" }}
					lg={{ span: 5, order: "last" }}
					style={{ minHeight: "70vh" }}
					className="px-0 mx-0 mb-0 pb-0 text-right"
				>
					<StaticImage
						style={{ minHeight: "70vh" }}
						className="px-0 mx-0 h-100"
						src="../images/wall-sockets.jpg"
						alt="Wall Sockets"
						placeholder="blurred"
						layout="FULL_WIDTH"
						quality="100"
						formats={["auto", "webp"]}
					/>
				</Col>
			</Row>
		</section>
	);
};

export default CtaBanner;
