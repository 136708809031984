import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Link from "./link";
import { StaticImage } from "gatsby-plugin-image";

const SmallDotsCta = ({ sHeading, heading, text, cta, ctaUrl, showCta }) => {
	const ctaVisible = showCta || "";
	return (
		<div>
			<Container className="pt-7 pb-4">
				<Row className="align-items-center align-middle mb-4">
					<Col lg={7} md={9}>
						<p style={{ fontSize: "1.2rem" }} className="text-primary">
							{sHeading}
						</p>
						<h3>{heading}</h3>
						<hr className=" float-left bg-primary mb-4" />
					</Col>
					<Col lg={5} md={3} className="text-right d-none d-md-block">
						<StaticImage
							quality="100"
							src="../images/dots-2.svg"
							alt="dots"
							placeholder="blurred"
							width={120}
							formats={["auto", "webp"]}
						/>
					</Col>
				</Row>
				<Row>
					<Col className=" ">
						<p>{text}</p>{" "}
						<Link
							to={ctaUrl}
							className={`cta-link font-weight-bold mt-5 ${ctaVisible}`}
						>
							{cta}
						</Link>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default SmallDotsCta;
