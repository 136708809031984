import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import ServiceHero from "../../components/service-hero";
import PhotoSide from "../../components/photo-side";
import Testimonials from "../../components/testimonials";
import Certifications from "../../components/certifications";
import SmallDotsCta from "../../components/small-dots-cta";
import CtaBanner from "../../components/cta-banner";
import { StaticImage } from "gatsby-plugin-image";

const Domestic = () => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImg: file(relativePath: { eq: "kitchen.jpg" }) {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: FULL_WIDTH
							placeholder: BLURRED
						)
					}
				}
				logoImg: file(relativePath: { eq: "logo.png" }) {
					publicURL
				}
				bulbImg: file(relativePath: { eq: "bulbs.png" }) {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
				reviews {
					reviewCount
				}
				site {
					siteMetadata {
						siteUrl
						company
					}
				}
			}
		`
	);
	const reviewCount = data.reviews.reviewCount;
	const schemaImg = data.heroImg.publicURL;
	const logoImg = data.logoImg.publicURL;
	const heroImg = data.heroImg.childImageSharp.gatsbyImageData;
	const bulbImg = data.bulbImg.childImageSharp.gatsbyImageData;
	const siteUrl = data.site.siteMetadata.siteUrl;
	const companyName = data.site.siteMetadata.company;
	const pageUrl = "/services/domestic";

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Prime Sparks",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: " Services",
				item:{ 
					url:`${siteUrl}/services`,
					id:`${siteUrl}/services`,
			},
		},
			{
				"@type": "ListItem",
				position: 3,
				name: "Domestic Electrical Services",
				item:{ 
					url:`${siteUrl}/services/domestic`,
					id:`${siteUrl}/services/domestic`,
			},
		},
		],
	};
	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": `${siteUrl}${pageUrl}#domestic-electrical-services`,
		image: `${siteUrl}${schemaImg}`,
		mpn: `${siteUrl}`,
		sku: `${siteUrl}${pageUrl}`,
		description: "Let Prime Sparks light up your home.",
		logo: `${siteUrl}${logoImg}`,
		name: `${companyName}`,
		url: `${siteUrl}`,
		brand: {
			"@type": "Organization",
			logo: `${siteUrl}${logoImg}`,
			image: `${siteUrl}${schemaImg}`,
			name: `${companyName}`,
			url: `${siteUrl}`,
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: `${reviewCount}`,
			ratingValue: "5",
			bestRating: "5",
			worstRating: "1",
		},
		review: {
			"@type": "Review",
			url: `${siteUrl}/testimonials`,
			datePublished: "2021-04-20T17:58:29+00:00",
			reviewBody:
				"I have used Prime Sparks on a number of occaisions. Roger responds quickly and carries out a professional service at competitive prices. On one call out he simply advised me on the right light bulbs to buy rather than quoting for upgraded fittings. Quality work and honest advice.",
			author: {
				"@type": "Person",
				name: "Ricky Carr",
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				bestRating: "5",
				worstRating: "1",
			},
			publisher: {
				"@type": "Organization",
				name: `${companyName}`,
				sameAs: `${siteUrl}`,
			},
		},
	};
	return (
		<Layout>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(serviceSchema)}
				</script>
			</Helmet>
			<GatsbySeo
				title="Domestic Electrical Services in Eastbourne | Prime Sparks"
				description="Let Prime Sparks light up your home."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: "Domestic Electrical Services | Prime Sparks",
					description: "Let Prime Sparks light up your home.",
					images: [
						{
							url: `${schemaImg}`,
							width: 1920,
							height: 1080,
							alt: "Domestic Electrical Services in Eastbourne",
						},
					],
				}}
			/>
			<ServiceHero
				heading="Domestic Electrical Services"
				text="Let Prime Sparks light up your home."
				isDarken={true}
				image={heroImg}
			/>
			<SmallDotsCta
				cta="GET IN TOUCH"
				ctaUrl="/contact-us"
				sHeading="Domestic Electrical Services in Eastbourne"
				heading={
					<span>
						Do you need some electrical work carried out in{" "}
						<span className="text-primary">your home</span>?
					</span>
				}
				text={
					<span>
						<p>
							If you’re looking for a reliable, local electrician in Eastbourne
							to carry out an electrical installation, repair or replacement,
							then relax, you’ve come to the right place… 
						</p>
						<p>
							As the preferred local electrician in Eastbourne and East Sussex
							we offer a prompt and friendly service, whether you would like
							some new lights fitted inside your home or outside to decorate
							your garden or enhance your security. 
						</p>
						<p>
							With nearly 20 years’ experience you can trust us to provided a
							high standard or service and do a professional job and you won’t
							have to clean up after us either as we always leave our work area
							completely spotless. 
						</p>
						<p>
							We also understand how important safety is to you when hiring an
							electrician to complete work in your home, which is why we test
							everything thoroughly before signing our work off and ensure that
							it is compliant with British Standard 7671 and Health and Safety
							legislation. Not only that, but we are also fully EICR and PAT
							qualified. With nearly 20 years’ experience as an electrician in
							East Sussex you can rest assured that Prime Sparks will provide
							you with a excellent, on-time service completed to the highest
							industry standard. 
						</p>
					</span>
				}
			/>
			<section id="services">
				<Container className="pt-7 pb-2 pb-lg-4">
					<h3 className="text-center">
						<span className="text-primary">Lighting</span> Installations
					</h3>

					<hr className=" bg-primary mb-3 mt-4" />
					<Row className="py-4 mb-lg-5">
						<Col md={4} lg={2} xs={12} className="text-center pb-4 pb-md-0">
							<div className="lighting-icon bg-primary  justify-content-center mx-auto">
								<Row className=" h-100 align-items-center">
									<Col>
										<StaticImage
											quality="100"
											src="../../images/icons/interior-lighting.svg"
											alt="Interior Lighting"
											placeholder="blurred"
											width={70}
											formats={["auto", "webp"]}
										/>
									</Col>
								</Row>
							</div>
							<p className="pt-3 lighting-icon-text">
								Interior
								<br /> Lighting
							</p>
						</Col>
						<Col md={4} lg={2} xs={12} className="text-center pb-4 pb-lg-0">
							<div className="lighting-icon bg-primary  justify-content-center mx-auto">
								<Row className=" h-100 align-items-center">
									<Col>
										<StaticImage
											quality="100"
											src="../../images/icons/led-lighting.svg"
											alt="Led Lighting"
											placeholder="blurred"
											width={70}
											formats={["auto", "webp"]}
										/>
									</Col>
								</Row>
							</div>
							<p className="pt-3 lighting-icon-text">
								LED <br />
								Lighting
							</p>
						</Col>
						<Col md={4} lg={2} xs={12} className="text-center pb-4 pb-lg-0">
							<div className="lighting-icon bg-primary  justify-content-center mx-auto">
								<Row className=" h-100 align-items-center">
									<Col>
										<StaticImage
											quality="100"
											src="../../images/icons/garden-lighting.svg"
											alt="Garden Lighting"
											placeholder="blurred"
											width={70}
											formats={["auto", "webp"]}
										/>
									</Col>
								</Row>
							</div>
							<p className="pt-3 lighting-icon-text">
								Garden <br />
								Lighting
							</p>
						</Col>
						<Col md={4} lg={2} xs={12} className="text-center pb-4 pb-lg-0">
							<div className="lighting-icon bg-primary  justify-content-center mx-auto">
								<Row className=" h-100 align-items-center">
									<Col>
										<StaticImage
											quality="100"
											src="../../images/icons/security-lighting.svg"
											alt="Security Lighting"
											placeholder="blurred"
											width={70}
											formats={["auto", "webp"]}
										/>
									</Col>
								</Row>
							</div>
							<p className="pt-3 lighting-icon-text">
								Security <br />
								Lighting
							</p>
						</Col>
						<Col md={4} lg={2} xs={12} className="text-center pb-4 pb-md-0">
							<div className="lighting-icon bg-primary  justify-content-center mx-auto">
								<Row className=" h-100 align-items-center">
									<Col>
										<StaticImage
											quality="100"
											src="../../images/icons/shower-lighting.svg"
											alt="Shower Lighting"
											placeholder="blurred"
											width={70}
											formats={["auto", "webp"]}
										/>
									</Col>
								</Row>
							</div>
							<p className="pt-3 lighting-icon-text">
								Shower <br />
								Lighting
							</p>
						</Col>
						<Col md={4} lg={2} xs={12} className="text-center ">
							<div className="lighting-icon bg-primary  justify-content-center mx-auto">
								<Row className=" h-100 align-items-center">
									<Col>
										<StaticImage
											quality="100"
											src="../../images/icons/outbuilding-lighting.svg"
											alt="Outbuilding Lighting"
											placeholder="blurred"
											width={70}
											formats={["auto", "webp"]}
										/>
									</Col>
								</Row>
							</div>
							<p className="pt-3 lighting-icon-text">
								Outbuilding <br /> Lighting
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<PhotoSide
				img={bulbImg}
				height="32rem"
				heading={
					<span>
						Are you looking for a{" "}
						<span className="text-primary">
							<br className="d-md-none" />
							reliable, local electrician{" "}
						</span>
						to repair or replace an electrical installation?
					</span>
				}
				cta="CONTACT US"
				ctaUrl="/contact-us"
				text="Prime Sparks offer a comprehensive range of domestic electrical services to customers in Eastbourne and the surrounding area and as fully qualified electricians you can rely on us to provide the absolute best service at a reasonable rate.  "
			/>
			<Certifications />
			<Testimonials />
			<CtaBanner
				headline="Do you need any of the following Electrical Services?"
				text={
					<span>
						<ul className="dashed pl-0">
							<li>- Servicing and maintenance of electrical appliances</li>
							<li>- Extra/replacement sockets</li>
							<li>- Additional lights installed</li>
							<li>- Fuse board upgrade/replacement</li>
							<li>- Security and outdoor lighting</li>
							<li>- Full or Partial rewiring</li>
							<li>- Telephone installation</li>
							<li>- Data networking</li>
							<li>- Electrical appliance repairs</li>
							<li>- Storage heater repairs/replacements</li>
							<li>- Fire alarm testing/installation</li>
						</ul>
					</span>
				}
			/>
		</Layout>
	);
};
export default Domestic;
